.button {
    appearance: none;
    --webkit-appearance: none;
    background-color: var(--primary);
    color: var(--white);
    border-radius: 14px;
    font-size: 1rem;
    padding: 13px 1em;
    border: none;
    font-family: 'Work Sans', sans-serif;
    transition: background-color 100ms;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    &:hover {
        background-color: var(--primary-tint);
    }
    span {
        margin-right: 0.25em;
    }

    &.button--block {
        width: 100%;
    }

   
}